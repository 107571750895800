import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Modal,
  Container,
  Card,
  CardMedia,
  IconButton
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOff from '@mui/icons-material/FilterListOff';
import vehiclesData from "./data/vehicles.json";
import mentorsData from "./data/mentors.json";
import skillsData from "./data/skills.json";
import weaponsData from "./data/weapons.json";
import qualitiesData from "./data/qualities.json"
import attachmentsData from "./data/attachments.json"
import talentsData from "./data/talents.json"
import craftingTemplates from "./data/craftingTemplates.json"
import craftingResultTables from "./data/craftingResultTables.json"
import FuzzySearch from "fuzzy-search";
import formatSource from "./Util/FormatSource";
import qualitiesString from "./Util/QualitiesString";
import VehicleCraftingModal from "./Util/VehicleCraftingModal";
import VehicleWeaponModal from "./Util/VehicleWeaponModal";
import MarkedText from "./Util/MarkedText";
import editQualities from "./Util/EditQualities";
import getModText from "./Util/GetModText";
import FilterModal from "./Util/FilterModal";


let vehicles = vehiclesData
let mentors = mentorsData
let skills = skillsData
let weapons = weaponsData
let attachments = attachmentsData
let talents = talentsData
let qualities = qualitiesData

function BuySellModal({ item, open, onClose, onSubmit, transaction }) {
  const [price, setPrice] = useState(0);
  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    setPrice(item.Price || 0);
  }, [item.Price, open]);

  const handleChangePrice = (event) => {
    const newPrice = Math.ceil(event.target.value);
    setPrice(newPrice);
    setPercentage((newPrice * 100) / item.Price);
  };

  const handleChangePercentage = (event) => {
    const newPercentage = Math.ceil(event.target.value);
    setPercentage(newPercentage);
    setPrice(Math.ceil((item.Price * newPercentage) / 100));
  };

  const handleSubmit = () => {
    onSubmit({ ...item, Price: price });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "25px",
        }}
      >
        <Typography variant="h6">
          {transaction} {item.Name}
        </Typography>
        <TextField
          label="Price"
          type="number"
          value={price}
          onChange={handleChangePrice}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Percentage"
          type="number"
          value={percentage}
          onChange={handleChangePercentage}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </Paper>
    </Modal>
  );
}

function VehicleTable(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const { setPreview, setPreviewOwned, owned, ownedVehicles, handleSell, handleBuy } = props;

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({
    field: null,
    direction: "asc",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [transactionType, setTransactionType] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [filter, setFilter] = useState("")

  const handleOpenModal = (item, transaction) => {
    setSelectedItem(item);
    setTransactionType(transaction);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const handleSubmitModal = (item, transaction) => {
    if (transaction === "Buy") {
      handleBuy(item);
    }
    if (transaction === "Sell") {
      handleSell(item);
    }
    handleCloseModal();
  };

  const handleSortClick = (field) => {
    setSortBy({
      field,
      direction:
        sortBy.field === field
          ? sortBy.direction === "asc"
            ? "desc"
            : "asc"
          : "asc",
    });
  };

  const sortData = (data) => {
    if (!sortBy.field) return data;

    const primarySortField = sortBy.field;
    const secondarySortField = sortBy.field + "Add"; // Replace with the name of the secondary field

    return data.sort((a, b) => {
      const sortDirection = sortBy.direction === "asc" ? 1 : -1;

      // Check if the sort fields exists on both objects
      const hasAPrimarySortField = primarySortField in a;
      const hasBPrimarySortField = primarySortField in b;
      const hasASecondarySortField = secondarySortField in a;
      const hasBSecondarySortField = secondarySortField in b;

      //no sorting when none of the fields exist
      if (
        !hasAPrimarySortField &&
        !hasBPrimarySortField &&
        !hasASecondarySortField &&
        !hasBSecondarySortField
      ) {
        return 0;
      }

      // Compare using the primary sort field if it exists on both objects
      if (hasAPrimarySortField && hasBPrimarySortField) {
        let valueA = a[primarySortField].toString().replace(/['" ]+/g, "");
        let valueB = b[primarySortField].toString().replace(/['" ]+/g, "");

        // Check if both values are integers after removing special characters
        const isValueANumber = !isNaN(parseInt(valueA)) && isFinite(valueA);
        const isValueBNumber = !isNaN(parseInt(valueB)) && isFinite(valueB);

        if (isValueANumber && isValueBNumber) {
          // Both values are integers, sort numerically
          return (parseInt(valueA) - parseInt(valueB)) * sortDirection;
        } else {
          // At least one value is not a number, sort as strings
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();

          if (valueA < valueB) {
            return -sortDirection;
          } else if (valueA > valueB) {
            return sortDirection;
          }
        }
      }
      //If only one object has primarySort, prioritize that one
      if (hasAPrimarySortField) {
        return sortDirection;
      }
      if (hasBPrimarySortField) {
        return -sortDirection;
      }
      // If the primary sort field doesn't exist on both objects, compare using the secondary sort field
      let valueA = (
        hasAPrimarySortField ? a[primarySortField] : a[secondarySortField]
      )
        .toString()
        .replace(/['" ]+/g, "");
      let valueB = (
        hasBPrimarySortField ? b[primarySortField] : b[secondarySortField]
      )
        .toString()
        .replace(/['" ]+/g, "");

      // Check if both values are integers after removing special characters
      const isValueANumber = !isNaN(parseInt(valueA)) && isFinite(valueA);
      const isValueBNumber = !isNaN(parseInt(valueB)) && isFinite(valueB);
      if (isValueANumber && isValueBNumber) {
        // Both values are integers, sort numerically
        return (parseInt(valueA) - parseInt(valueB)) * sortDirection;
      } else {
        // At least one value is not a number, sort as strings
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();

        if (valueA < valueB) {
          return -sortDirection;
        } else if (valueA > valueB) {
          return sortDirection;
        }
      }

      return 0;
    });
  };

  const sortedData = () => {
    let data = owned ? ownedVehicles : vehicles;

    if (selectedType) {
      data = data.filter((item) => (item.Categories && (item.Categories === selectedType || item.Categories.includes(selectedType))));
    }
    if (filter) {
      data = data.filter(filter)
    }

    const sorted = sortData(data);
    return sorted;
  };

  const searcher = new FuzzySearch(sortedData(), ["Name"], {
    caseSensitive: false,
  });

  const searchResults = searcher.search(search);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={5} lg={6} >
          <TextField
            fullWidth
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            size={isLargeScreen ? 'medium' : 'small'}
          />
        </Grid>
        <Grid item xs={10} sm={5} >
          <FormControl fullWidth>
            <InputLabel
              sx={{
                transform: selectedType ? 'translate(14px, -10px)' : isLargeScreen ? 'translate(14px, 14px)' : 'translate(14px, 9px)',
                transition: 'transform 200ms ease-in-out',
                fontSize: (!isLargeScreen && selectedType) ? '0.875rem' : '1rem',
              }}
            >
              Type
            </InputLabel>
            <Select
              value={selectedType}
              onChange={(event) => setSelectedType(event.target.value)}
              size={isLargeScreen ? 'medium' : 'small'}
            >
              {["Starship", "Land Vehicle", "Air Vehicle", "Walker", "Watercraft", "Station"].map((type, index) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} lg={1} container justifyContent="center" alignItems="center">
          <IconButton
            onClick={() => setFilterModalOpen(true)}
            sx={{ maxWidth: "30px" }}
            size="small"
            className="no-print"
          >
            <FilterListIcon />
          </IconButton>
          <IconButton
            onClick={() => setFilter("")}
            sx={{ maxWidth: "30px" }}
            size="small"
            className="no-print"
          >
            <FilterListOff />
          </IconButton>
        </Grid>
      </Grid>
      <FilterModal open={filterModalOpen} handleClose={() => setFilterModalOpen(false)} filter={filter} setFilter={setFilter} category={"Vehicles"} data={sortedData()} />
      <TableContainer
        component={Paper}
        style={{
          maxHeight: isLargeScreen ? "calc(100vh - 23vh)" : "40vh",
          minHeight: "8vh",
          overflowY: "auto",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow hover sx={{ cursor: "pointer" }}>
              <TableCell onClick={() => handleSortClick("Name")}>
                {sortBy.field != "Name" && "Name"}
                {sortBy.field === "Name" && (
                  <div>Name {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => handleSortClick("Silhouette")}
              >
                {sortBy.field != "Silhouette" && "Sil"}
                {sortBy.field === "Silhouette" && (
                  <div>Sil {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>
              {!isSmallScreen && <TableCell align="center" onClick={() => handleSortClick("Speed")}>
                {sortBy.field != "Speed" && "Speed"}
                {sortBy.field === "Speed" && (
                  <div>Speed {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>}
              {!isSmallScreen && <TableCell align="center" onClick={() => handleSortClick("Armor")}>
                {sortBy.field != "Armor" && "Armor"}
                {sortBy.field === "Armor" && (
                  <div>Armor {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>}
              <TableCell align="right" onClick={() => handleSortClick("Price")}>
                {sortBy.field != "Price" && "Price"}
                {sortBy.field === "Price" && (
                  <div>Price {sortBy.direction === "asc" ? "˅" : "˄"}</div>
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((item) => (
              <TableRow
                hover
                key={item.id}
                onClick={() => {
                  setPreview(item);
                  setPreviewOwned(owned);
                }}
                sx={{ cursor: "pointer" }}
              >
                <TableCell>{item.Name}</TableCell>
                <TableCell align="center">{item.Silhouette}</TableCell>
                {!isSmallScreen && <TableCell align="center">{item.Speed}</TableCell>}
                {!isSmallScreen && <TableCell align="center">{item.Armor}</TableCell>}
                <TableCell align="right">{item.Price}</TableCell>
                <TableCell align="right">
                  {!owned && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => handleOpenModal(item, "Buy")}
                      size="small"
                      style={{
                        fontSize: "0.7rem",
                        minWidth: "30px",
                      }}
                    >
                      Buy
                    </Button>
                  )}
                  {owned && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => handleOpenModal(item, "Sell")}
                      size="small"
                      style={{
                        fontSize: "0.7rem",
                        minWidth: "30px",
                      }}
                    >
                      Sell
                    </Button>
                  )}
                  {modalOpen && item.Key === selectedItem.Key && (
                    <BuySellModal
                      item={item}
                      open={modalOpen}
                      onClose={handleCloseModal}
                      onSubmit={(item) =>
                        handleSubmitModal(item, transactionType)
                      }
                      transaction={transactionType}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer >
    </>
  );
}

function App({ character, handleCharacterChange, appFiles }) {
  useEffect(() => {
    if (appFiles.vehicles) { vehicles = JSON.parse(appFiles.vehicles) }
    if (appFiles.mentors) { mentors = JSON.parse(appFiles.mentors) }
    if (appFiles.skills) { skills = JSON.parse(appFiles.skills) }
    if (appFiles.weapons) { weapons = JSON.parse(appFiles.weapons) }
    if (appFiles.attachments) { attachments = JSON.parse(appFiles.attachments) }
    if (appFiles.talents) { talents = JSON.parse(appFiles.talents) }
    if (appFiles.qualities) { qualities = JSON.parse(appFiles.qualities) }
  }, [appFiles.weapons, appFiles.skills, appFiles.mentors, appFiles.vehicles, appFiles.attachments, appFiles.talents, appFiles.qualities]);

  const [toggles, setToggles] = useState(
    character.GroupToggles || {
      Vehicle: true,
      Mentor: false,
      Holocron: false,
    },
  );
  const [selectedMentorTraining, setSelectedMentorTraining] = useState(character.Mentor.Training.Index || "",);
  const [selectedMentorCW, setSelectedMentorCW] = useState(character.Mentor.CW.Index || "",);
  const [selectedMentorPurge, setSelectedMentorPurge] = useState(character.Mentor.Purge.Index || "",);
  const [selectedMentorTradition, setSelectedMentorTradition] = useState(character.Mentor.ForceTradition?.Index || "",);
  const [selectedMentorPersonality, setSelectedMentorPersonality] = useState(character.Mentor.Personality.Index || "",);
  const [selectedMentorComplication, setSelectedMentorComplication] = useState(character.Mentor.Complication.Index || "",);
  const [removedDrawback, setRemovedDrawback] = useState(character.Mentor.ForceTradition?.Drawback?.Removed || false)
  const [selectedHolocron, setSelectedHolocron] = useState(character.Holocron || [],);
  const [mentorDescription, setMentorDescription] = useState(character.Mentor.Description || "",);
  const [preview, setPreview] = useState({});
  const [ownedVehicles, setOwnedVehicles] = useState(character.Vehicles);
  const [craftingModalOpen, setCraftingModalOpen] = useState(false)
  const [previewOwned, setPreviewOwned] = useState(false);
  const [editedItem, setEditedItem] = useState({})
  const [openAttachments, setOpenAttachments] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = useState("");
  const [modStates, setModStates] = useState({});
  const [weaponsModalOpen, setWeaponsModalOpen] = useState(false)

  useEffect(() => {
    if (preview) {
      setModStates(preview.ModStates || {});
    }
  }, [preview]);

  useEffect(() => {
    let newPreview = {}
    if (!previewOwned) {
      if (ownedVehicles[0]) {
        newPreview =
          ownedVehicles.slice(-1)[0]
      }
    }
    if (weaponsModalOpen || openAttachments) {
      setWeaponsModalOpen(false)
      setOpenAttachments(false)
    } else {
      setPreviewOwned(!previewOwned)
      setPreview(newPreview);
    }
  }, [ownedVehicles]);

  const handleBuy = (item) => {
    const UID = `${item.Key}_${Date.now()}`;
    item.inventoryID = UID;
    if (!item.Price) {
      item.Price = 0;
    }
    let newVehicles = [...ownedVehicles];
    newVehicles.push(item);
    setOwnedVehicles(newVehicles);
    handleCharacterChange({
      Vehicles: newVehicles,
      Credits: character.Credits - item.Price,
    });
    setPreview(item);
    setPreviewOwned(true)
  };

  const handleSell = (item) => {
    let newVehicles = [...ownedVehicles];
    newVehicles.splice(
      newVehicles.findIndex((el) => el.Key === item.Key),
      1,
    );
    setOwnedVehicles(newVehicles);
    handleCharacterChange({
      Vehicles: newVehicles,
      Credits: character.Credits + parseInt(item.Price),
    });
    setPreviewOwned(false)
    setPreview({})
  };

  const handleSaveAttachments = (item, attachment) => {
    let newCategory = [...character.Vehicles];
    if (!item.Attachments) { item.Attachments = [] }

    if (attachment.AddedMods) {
      attachment.AddedMods.forEach((mod) => {
        setModStates((prevModStates) => ({
          ...prevModStates,
          [`${item.inventoryID}-${attachment.Key}-${mod.Key}`]: {
            installed: Array(parseInt(mod.Count || 1)).fill(false),
            failed: Array(parseInt(mod.Count || 1)).fill(false),
          },
        }));
      });
    }
    item.Attachments.push(attachment);
    item.HPUsed = (item.HPUsed || 0) + parseInt(attachment.HP);
    item.Price = parseInt(item.Price) + parseInt(attachment.Price);
    let modItem = editQualities(item, attachment.BaseMods)
    newCategory[newCategory.findIndex((i) => i.inventoryID === item.inventoryID)] = modItem;
    setOwnedVehicles(newCategory)
    handleCharacterChange({
      Vehicles: newCategory,
      Credits: character.Credits - parseInt(attachment.Price),
    });
    setPreview(modItem)
  };

  const handleRemoveAttachment = (item, attachment, attachI) => {
    let newCategory = [...character.Vehicles];
    attachment.AddedMods.forEach((mod) => {
      setModStates((prevModStates) => {
        const { [`${item.inventoryID}-${attachment.Key}-${mod.Key}`]: _, ...rest } = prevModStates;
        return rest;
      });
    });
    item.Attachments.splice(attachI, 1);
    item.HPUsed = (item.HPUsed || 0) - parseInt(attachment.HP || 0);
    item.Price = parseInt(item.Price) - parseInt(attachment.Price);
    let modItem = editQualities(item, attachment.BaseMods, true)
    setPreview(modItem)
    newCategory[newCategory.findIndex((i) => i.inventoryID === item.inventoryID)] = modItem;
    handleCharacterChange({
      Vehicles: newCategory,
    });
  };

  const handleRefundAttachment = (item, attachment, attachI) => {
    let newCategory = [...character.Vehicles];

    if (attachment.AddedMods) {
      attachment.AddedMods.forEach((mod) => {
        setModStates((prevModStates) => {
          const { [`${item.inventoryID}-${attachment.Key}-${mod.Key}`]: _, ...rest } = prevModStates;
          return rest;
        });
      });
    }
    item.Attachments.splice(attachI, 1);
    item.HPUsed = (item.HPUsed || 0) - parseInt(attachment.HP || 0);
    item.Price = parseInt(item.Price) - parseInt(attachment.Price);
    let modItem = editQualities(item, attachment.BaseMods, true)
    setPreview(modItem)
    newCategory[newCategory.findIndex((i) => i.inventoryID === item.inventoryID)] = modItem;
    handleCharacterChange({
      Vehicles: newCategory,
      Credits: character.Credits + parseInt(attachment.Price),
    });
  };

  const handleMentorDescriptionChange = (event) => {
    let mentor = character.Mentor || {};
    mentor.Description = event.target.value;
    setMentorDescription(event.target.value);
    handleCharacterChange({ Mentor: mentor });
  };

  const handleMentorTrainingChange = (event) => {
    let mentor = character.Mentor || {};
    mentor.Training = mentors.MentorHistory.Training[event.target.value];
    mentor.Training.Index = event.target.value;
    setSelectedMentorTraining(event.target.value);
    handleCharacterChange({ Mentor: mentor });
  };

  const handleMentorCWChange = (event) => {
    let mentor = character.Mentor || {};
    mentor.CW = mentors.MentorHistory.TheCloneWars[event.target.value];
    mentor.CW.Index = event.target.value;
    setSelectedMentorCW(event.target.value);
    handleCharacterChange({ Mentor: mentor });
  };

  const handleMentorPurgeChange = (event) => {
    let mentor = character.Mentor || {};
    mentor.Purge = mentors.MentorHistory.SurvivingThePurge[event.target.value];
    mentor.Purge.Index = event.target.value;
    setSelectedMentorPurge(event.target.value);
    handleCharacterChange({ Mentor: mentor });
  };

  const handleMentorPersonalityChange = (event) => {
    let mentor = character.Mentor || {};
    mentor.Personality = mentors.MentorPersonality[event.target.value];
    mentor.Personality.Index = event.target.value;
    setSelectedMentorPersonality(event.target.value);
    handleCharacterChange({ Mentor: mentor });
  };

  const handleMentorComplicationChange = (event) => {
    let mentor = character.Mentor || {};
    mentor.Complication = mentors.MentorComplications[event.target.value];
    mentor.Complication.Index = event.target.value;
    setSelectedMentorComplication(event.target.value);
    handleCharacterChange({ Mentor: mentor });
  };

  const handleMentorTraditionChange = (event) => {
    let mentor = character.Mentor || {};
    mentor.ForceTradition = mentors.MentorForceTraditions[event.target.value];
    mentor.ForceTradition.Index = event.target.value;
    setSelectedMentorTradition(event.target.value);
    handleCharacterChange({ Mentor: mentor });
  };

  const handleHolocronToggle = (skill) => {
    let holocron = selectedHolocron;
    if (selectedHolocron.includes(skill)) {
      holocron = holocron.filter((selectedSkill) => selectedSkill !== skill);
    } else {
      if (selectedHolocron.length < 2) {
        holocron = [...selectedHolocron, skill];
      }
    }
    setSelectedHolocron(holocron);
    handleCharacterChange({ Holocron: holocron });
  };

  const getWeapon = (key) => {
    return weapons.find((w) => w.Key === key);
  };

  const WeaponPanel = (weaponObject) => {
    let vehicleWeapon = {}
    weaponObject.weapon ? vehicleWeapon = weaponObject.weapon : vehicleWeapon = weaponObject
    let weapon = {}
    vehicleWeapon.Key ? weapon = getWeapon(vehicleWeapon.Key) : weapon = vehicleWeapon
    if (vehicleWeapon.VehicleWeaponProperties) { vehicleWeapon = vehicleWeapon.VehicleWeaponProperties }

    const qualitiesList = qualitiesString(
      weapon?.Qualities,
      vehicleWeapon?.Qualities,
    );

    return (
      <ListItem dense>
        <Paper variant="outlined" style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h9">{weapon.Name + (vehicleWeapon.Turret ? " Turret" : "")}</Typography>{" "}
            </Grid>
            <Grid item xs={12} sm={6}>
              {vehicleWeapon.FiringArcs && <div><Typography variant="caption">
                Fire Arc:{" "}
                {Object.entries(vehicleWeapon.FiringArcs)
                  .filter(([arcName, value]) => value === "true")
                  .map(([arcName]) => arcName)
                  .join(", ")}
              </Typography>
                <br /></div>}
              {vehicleWeapon.SkillKey && <div><Typography variant="caption">Skill: {skills.find(s => s.Key === vehicleWeapon.SkillKey).skill}</Typography><br /></div>}
              {vehicleWeapon.Scale === "wsPersonal" && <div><Typography variant="caption">Personal Scale</Typography><br /></div>}
              <Typography variant="caption">Damage: {weapon.Damage || '-'}</Typography>
              <br />
              <Typography variant="caption">Crit: {weapon.Crit || '-'}</Typography>
              <br />
              <Typography variant="caption">Range: {weapon.Range}</Typography>
              <br />
              {(weapon.Qualities || qualitiesList) && (
                <Typography variant="caption">{qualitiesList || weapon.Qualities}</Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </ListItem>
    );
  };

  const handleOpenAttachments = (item) => {
    setEditedItem(item);
    setOpenAttachments(true);
  };

  const handleCloseAttachments = () => {
    setOpenAttachments(false);
  };

  const toggle = (key) => {
    let newTog = toggles;
    newTog[key] = !newTog[key];
    setToggles(newTog);
    handleCharacterChange({ GroupToggles: newTog });
  };

  const getAttachmentWeapons = (vehicle) => {
    let attWeaps = []
    if (vehicle.Attachments) {
      for (let a in vehicle.Attachments) {
        let att = vehicle.Attachments[a]
        if (att.WeaponModifiers) {
          for (let w in att.WeaponModifiers) {
            let weap = att.WeaponModifiers[w]
            weap.FiringArcs = weap.VehicleWeaponProperties?.FiringArcs || {}
            weap.Location = weap.VehicleWeaponProperties?.Location || ""

            attWeaps.push(weap)
          }

        }
      }
    }
    return attWeaps
  }

  return (
    <>
      <Grid container sx={{ height: '50px' }}>
        <Grid item xs={8} lg={6} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', height: '100%' }}>
          <FormControlLabel
            key="vehicle"
            control={
              <Checkbox
                checked={toggles.Vehicle}
                onChange={() => toggle("Vehicle")}
              />
            }
            label="Vehicle"
          />
          <FormControlLabel
            key="mentor"
            control={
              <Checkbox
                checked={toggles.Mentor}
                onChange={() => toggle("Mentor")}
              />
            }
            label="Mentor"
          />
          <FormControlLabel
            key="holocron"
            control={
              <Checkbox
                checked={toggles.Holocron}
                onChange={() => toggle("Holocron")}
              />
            }
            label="Holocron"
          />
        </Grid>
        <Grid item xs={4} lg={6} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', height: '100%' }}>
          <Button onClick={() => setCraftingModalOpen(true)}>Craft Vehicle</Button>
        </Grid>
      </Grid>
      <Paper elevation={3} style={{ padding: "25px" }}>
        <Grid container spacing={2}>
          {toggles.Vehicle && (
            <Grid container>
              <Grid item xs={12} lg={6}>
                <VehicleTable
                  setPreview={setPreview}
                  setPreviewOwned={setPreviewOwned}
                  owned={false}
                  ownedVehicles={ownedVehicles}
                  handleSell={handleSell}
                  handleBuy={handleBuy}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <VehicleTable
                  setPreview={setPreview}
                  setPreviewOwned={setPreviewOwned}
                  owned={true}
                  ownedVehicles={ownedVehicles}
                  handleSell={handleSell}
                  handleBuy={handleBuy}
                />

                {preview.Name && (<Paper variant="outlined" style={{ minHeight: "20vh" }}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" style={{ padding: "15px" }}>
                        {preview.Name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {previewOwned && (
                        <Button onClick={() => handleOpenAttachments(preview)}>
                          Attachments
                        </Button>)}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <List>
                        {!isNaN(preview.Price) && (
                          <ListItem>{`Price: ${preview.Price} credits`}</ListItem>
                        )}
                        {preview.Rarity && (
                          <ListItem>{`Rarity: ${preview.Rarity}${preview.Restricted ? ", Restricted " : ""
                            }`}</ListItem>
                        )}
                        <ListItem>{`Sensor Range: ${preview.SensorRange || "none"
                          }`}</ListItem>
                        {preview.NaviComputer && (
                          <ListItem>{`Navicomputer: ${preview.NaviComputer ? "Yes" : "No"
                            }`}</ListItem>
                        )}
                        {preview.HyperdrivePrimary && (
                          <ListItem>
                            {`Hyperdrive: Primary Class ${preview.HyperdrivePrimary
                              }${preview.HyperdriveBackup
                                ? ", Backup Class " + preview.HyperdriveBackup
                                : ""
                              }`}
                          </ListItem>
                        )}
                        {preview.StarFighters && (
                          <ListItem>{`Starfighters: ${preview.StarFighters}`}</ListItem>
                        )}
                        {preview.Crew && (
                          <ListItem>{`Crew: ${preview.Crew}`}</ListItem>
                        )}
                        {preview.Passengers && (
                          <ListItem>{`Passengers: ${preview.Passengers}`}</ListItem>
                        )}
                        {preview.EncumbranceCapacity && (
                          <ListItem>{`Encumbrance Capacity: ${preview.EncumbranceCapacity}`}</ListItem>
                        )}
                        {preview.Consumables && (
                          <ListItem>{`Consumables: ${preview.Consumables}`}</ListItem>
                        )}
                        {preview.Massive && (
                          <ListItem>{`Massive: ${preview.Massive}`}</ListItem>
                        )}
                        <ListItem>{`Hard Points: ${preview.HP || 0
                          }`}</ListItem>
                        {preview.imageUrl && <Card
                          style={{
                            maxHeight: "500px",
                            maxWidth: "100%",
                            minHeight: "20px",
                            minWidth: "200px",
                            display: "flex",
                            padding: "8px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={preview.imageUrl}
                            alt="Item Image"
                            style={{ objectFit: "contain" }}
                          />
                        </Card>}
                        <ListItem> <div
                          style={{ padding: "15px" }}
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(preview.Description),
                          }}
                        /></ListItem>
                        <ListItem>{formatSource(preview.Source)}</ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Table
                        size="small"
                        sx={{
                          "& .MuiTableCell-root": { textAlign: "center" },
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell>Armor</TableCell>
                            <TableCell>Hull Trauma</TableCell>
                            <TableCell>System Strain</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h6">{`${preview.Armor || 0
                                }`}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6">{`${preview.HullTrauma || 0
                                }`}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6">{`${preview.SystemStrain || 0
                                }`}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h6">{`${preview.Silhouette || 0
                                }`}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6">{`${preview.Speed || 0
                                }`}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6">{`${preview.Handling || 0
                                }`}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Silhouette </TableCell>
                            <TableCell>Speed </TableCell>
                            <TableCell>Handling </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Typography
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Defense Fore|Port|Starboard|Aft:
                      </Typography>
                      <Typography
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {preview.DefFore || 0} | {preview.DefPort || "-"} |{" "}
                        {preview.DefStarboard || "-"} | {preview.DefAft || 0}
                      </Typography>
                      {preview.BaseMods && preview.BaseMods.map((mod) => (
                        <div
                          style={{ padding: "5px" }}
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(mod.MiscDesc),
                          }}
                        />
                      ))}
                      {preview.Attachments && (
                        <Container>
                          {preview.Attachments[0] && (
                            <Typography variant="h6">Attachments</Typography>
                          )}
                          {preview.Attachments.map((attachment, attachI) => (
                            <Paper key={attachment.Key + attachI} variant="outlined">
                              <Typography sx={{ mt: "5px" }}>
                                {attachment.Name}
                              </Typography>
                              <Typography sx={{ mt: "5px" }}>
                                HP: {attachment.HP}
                              </Typography>
                              {attachment.BaseMods && (
                                <Typography sx={{ mt: "5px" }}>
                                  Base Modifiers:{" "}
                                  {attachment.BaseMods.map((mod, index) => (
                                    <div key={attachment.Key + (mod.Key || "") + index}>
                                      {mod.MiscDesc && (<div
                                        style={{ padding: "5px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: MarkedText.renderer(mod.MiscDesc),
                                        }}
                                      />)}
                                      {mod.Key && (
                                        <div
                                          style={{ padding: "5px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                          }}
                                        />
                                      )}
                                    </div>
                                  ))}
                                </Typography>
                              )}
                              {attachment.AddedMods && (
                                <Typography sx={{ mt: "5px" }}>
                                  Modification Options:{" "}
                                  {attachment.AddedMods.map((mod, index) => {
                                    const modKey = `${preview.inventoryID}-${attachment.Key}-${(mod.Key || Date.now) + index}`;
                                    const modState = modStates[modKey] || {
                                      installed: Array(parseInt(mod.Count || 1)).fill(false),
                                      failed: Array(parseInt(mod.Count || 1)).fill(false),
                                    };
                                    return (
                                      <div key={mod.Key + index}>
                                        <div>
                                          <div>
                                            {Array(parseInt(mod.Count ? mod.Count : 1))
                                              .fill(0)
                                              .map((_, i) => (
                                                <div key={`${mod.Key}-${i}`}>
                                                  <Grid container>
                                                    {mod.MiscDesc && <Grid item>
                                                      <div
                                                        style={{ padding: "5px" }}
                                                        dangerouslySetInnerHTML={{
                                                          __html: MarkedText.renderer(mod.MiscDesc),
                                                        }}
                                                      />
                                                    </Grid>}
                                                    {mod.Key && <Grid item style={{ display: "flex", alignItems: "center" }}>
                                                      <div
                                                        style={{
                                                          padding: "5px",
                                                          margin: "auto",
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                          __html: MarkedText.renderer(getModText(qualities, talents, skills, mod, true)),
                                                        }}
                                                      />
                                                    </Grid>}
                                                    <Grid item>
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={modState.installed[i]}
                                                            onChange={(event) => {
                                                              const isInstalled = event.target.checked;
                                                              let newModStates = {
                                                                ...modStates,
                                                                [modKey]: {
                                                                  ...modState,
                                                                  installed: modState.installed.map(
                                                                    (installed, j) => (j === i ? isInstalled : installed)
                                                                  ),
                                                                  failed: modState.failed.map(
                                                                    (failed, j) => (j === i ? false : failed)
                                                                  ),
                                                                },
                                                              }
                                                              setModStates(newModStates);
                                                              let oneMod = { ...mod };
                                                              if (oneMod.Count) {
                                                                oneMod.Count = 1;
                                                              }
                                                              let eq = editQualities(preview, [oneMod], isInstalled ? false : true);
                                                              eq.ModStates = newModStates
                                                              setPreview(eq)
                                                              let newCategory = [...character.Vehicles]
                                                              newCategory[newCategory.findIndex((i) => i.inventoryID === preview.inventoryID)] = eq;
                                                              handleCharacterChange({
                                                                Vehicles: newCategory,
                                                                Credits: character.Credits + (isInstalled ? -100 : 100),
                                                              });
                                                            }}
                                                            disabled={modState.failed[i]}
                                                          />
                                                        }
                                                        label="Installed"
                                                      />
                                                    </Grid>
                                                    <Grid item>
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={modState.failed[i]}
                                                            onChange={(event) => {
                                                              const isFailed = event.target.checked;
                                                              let newModStates = {
                                                                ...modStates,
                                                                [modKey]: {
                                                                  ...modState,
                                                                  failed: modState.failed.map(
                                                                    (failed, j) => (j === i ? isFailed : failed)
                                                                  ),
                                                                  installed: modState.installed.map(
                                                                    (installed, j) => (j === i ? false : installed)
                                                                  ),
                                                                },
                                                              }
                                                              setModStates(newModStates);
                                                              preview.ModStates = newModStates
                                                              let newCategory = [...character.Vehicles]
                                                              newCategory[newCategory.findIndex((i) => i.inventoryID === preview.inventoryID)] = preview;
                                                              handleCharacterChange({
                                                                Vehicles: newCategory,
                                                                Credits: character.Credits + (isFailed ? -100 : 100),
                                                              });
                                                            }}
                                                            disabled={modState.installed[i]}
                                                          />
                                                        }
                                                        label="Failed"
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Typography>
                              )}
                              {!attachment.Key.includes("CRAFT") && <>
                                <Button
                                  onClick={() =>
                                    handleRemoveAttachment(
                                      preview,
                                      attachment,
                                      attachI,
                                    )
                                  }
                                >
                                  Remove
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleRefundAttachment(
                                      preview,
                                      attachment,
                                      attachI,
                                    )
                                  }
                                >
                                  Refund
                                </Button>
                              </>}
                            </Paper>
                          ))}
                        </Container>
                      )}
                      <List>
                        {preview.VehicleWeapons?.map(
                          (weapon, index) => (
                            <WeaponPanel key={index} weapon={weapon} />
                          ),
                        )}
                        {preview.WeaponModifiers?.map(
                          (weapon, index) => (
                            <WeaponPanel key={index} weapon={weapon} />
                          ),
                        )}
                        {getAttachmentWeapons(preview).map(
                          (weapon, index) => (
                            < WeaponPanel key={index} weapon={weapon} />
                          )
                        )}
                      </List>
                      {previewOwned && <Button onClick={() => setWeaponsModalOpen(true)}>Edit Weapons</Button>}
                      <VehicleWeaponModal
                        key={preview.Key + preview.Attachments?.length || 0}
                        open={weaponsModalOpen}
                        handleClose={() => setWeaponsModalOpen(false)}
                        character={character}
                        handleCharacterChange={handleCharacterChange}
                        vehicle={preview}
                        setOwnedVehicles={setOwnedVehicles}
                        setPreview={setPreview}
                        weapons={weapons}
                        qualities={qualities}
                        talents={talents}
                        skills={skills}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                )}
              </Grid>
              <VehicleCraftingModal
                open={craftingModalOpen}
                handleClose={() => setCraftingModalOpen(false)}
                craftingTemplates={craftingTemplates}
                craftingResultTables={craftingResultTables}
                character={character}
                handleCharacterChange={handleCharacterChange}
                setOwnedVehicles={setOwnedVehicles}
                qualities={qualities}
                talents={talents}
                skills={skills}
              />
            </Grid>
          )}
          {toggles.Mentor && (
            <Grid item xs={12} sm={4}>
              <Typography variant="h5">Mentor</Typography>
              <Typography variant="h6">History</Typography>
              <Paper style={{ minHeight: "50vh", maxHeight: "85vh", overflow: "auto" }}>
                <InputLabel>Training</InputLabel>
                <FormControl fullWidth>
                  <Select
                    value={selectedMentorTraining}
                    onChange={handleMentorTrainingChange}
                  >
                    {mentors.MentorHistory.Training.map((mentor, index) => (
                      <MenuItem key={index} value={index}>
                        {mentor.Title}
                      </MenuItem>
                    ))}
                  </Select>
                  {mentors.MentorHistory.Training[selectedMentorTraining] && (
                    <Typography>
                      {
                        mentors.MentorHistory.Training[selectedMentorTraining]
                          .Description
                      }
                    </Typography>
                  )}
                </FormControl>
                <InputLabel>The Clone Wars</InputLabel>
                <FormControl fullWidth>
                  <Select
                    value={selectedMentorCW}
                    onChange={handleMentorCWChange}
                  >
                    {mentors.MentorHistory.TheCloneWars.map((mentor, index) => (
                      <MenuItem key={index} value={index}>
                        {mentor.Title}
                      </MenuItem>
                    ))}
                  </Select>
                  {mentors.MentorHistory.TheCloneWars[selectedMentorCW] && (
                    <Typography>
                      {
                        mentors.MentorHistory.TheCloneWars[selectedMentorCW]
                          .Description
                      }
                    </Typography>
                  )}
                </FormControl>
                <InputLabel>Surviving the Purge</InputLabel>
                <FormControl fullWidth>
                  <Select
                    value={selectedMentorPurge}
                    onChange={handleMentorPurgeChange}
                  >
                    {mentors.MentorHistory.SurvivingThePurge.map(
                      (mentor, index) => (
                        <MenuItem key={index} value={index}>
                          {mentor.Title}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                  {mentors.MentorHistory.SurvivingThePurge[
                    selectedMentorPurge
                  ] && (
                      <Typography>
                        {
                          mentors.MentorHistory.SurvivingThePurge[
                            selectedMentorPurge
                          ].Description
                        }
                      </Typography>
                    )}
                </FormControl>
                <Typography variant="h6">Personality</Typography>
                <FormControl fullWidth>
                  <Select
                    value={selectedMentorPersonality}
                    onChange={handleMentorPersonalityChange}
                  >
                    {mentors.MentorPersonality.map((mentor, index) => (
                      <MenuItem key={index} value={index}>
                        {mentor.Style}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {mentors.MentorPersonality[selectedMentorPersonality] && (
                  <Typography>
                    {
                      mentors.MentorPersonality[selectedMentorPersonality]
                        .Details
                    }
                  </Typography>
                )}
                <Typography variant="h6">Complications</Typography>
                <FormControl fullWidth>
                  <Select
                    value={selectedMentorComplication}
                    onChange={handleMentorComplicationChange}
                  >
                    {mentors.MentorComplications.map((mentor, index) => (
                      <MenuItem key={index} value={index}>
                        {mentor.Complication}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {mentors.MentorComplications[selectedMentorComplication] && (
                  <Typography>
                    {
                      mentors.MentorComplications[selectedMentorComplication]
                        .Details
                    }
                  </Typography>
                )}
                <Typography variant="h6">Alternative Traditions</Typography>
                <FormControl fullWidth>
                  <Select
                    value={selectedMentorTradition}
                    onChange={handleMentorTraditionChange}
                  >
                    {mentors.MentorForceTraditions.map((mentor, index) => (
                      <MenuItem key={index} value={index}>
                        {mentor.Tradition}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {mentors.MentorForceTraditions[selectedMentorTradition] && (<>
                  <Typography>{mentors.MentorForceTraditions[selectedMentorTradition].Description}</Typography>
                  {mentors.MentorForceTraditions[selectedMentorTradition].Benefit.Description &&
                    <Typography><b>Benefit: </b>{mentors.MentorForceTraditions[selectedMentorTradition].Benefit.Description}</Typography>
                  }
                  {mentors.MentorForceTraditions[selectedMentorTradition].Drawback.Description &&
                    <Typography style={{ textDecoration: removedDrawback ? 'line-through' : 'none' }}                    >
                      <b>Drawback: </b>{mentors.MentorForceTraditions[selectedMentorTradition].Drawback.Description}
                    </Typography>
                  }
                  <FormControlLabel
                    key="mentorDrawback"
                    control={
                      <Checkbox
                        checked={removedDrawback}
                        onChange={() => {
                          let newMentor = { ...character.Mentor }
                          let newXP = parseInt(character.XP)
                          if (newMentor.ForceTradition.Drawback.Removed) {
                            delete newMentor.ForceTradition.Drawback.Removed
                            newXP += newMentor.ForceTradition.Drawback.Cost
                          } else {
                            newMentor.ForceTradition.Drawback.Removed = true
                            newXP -= newMentor.ForceTradition.Drawback.Cost
                          }
                          handleCharacterChange({ Mentor: newMentor, XP: newXP })
                          setRemovedDrawback(!removedDrawback)
                        }}
                      />
                    }
                    label={"Remove Drawback: " + character.Mentor.ForceTradition.Drawback.Cost + " XP"}
                  />
                </>
                )}
                <Typography variant="h6">Descpription</Typography>
                <TextField
                  fullWidth
                  multiline
                  value={mentorDescription}
                  onChange={handleMentorDescriptionChange}
                />
              </Paper>
            </Grid>
          )}
          {toggles.Holocron && (
            <Grid item xs={12} sm={4}>
              <Typography variant="h5">Holocron</Typography>
              <Typography>Select 2 skills</Typography>
              <Paper style={{ maxHeight: 500, overflow: "auto" }}>
                <List>
                  {skills.map((skill, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={skill.skill} />
                      <Checkbox
                        checked={selectedHolocron.includes(skill)}
                        onChange={() => handleHolocronToggle(skill)}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}
        </Grid>
        {
          openAttachments &&
          editedItem &&
          editedItem.Key === preview.Key && (
            <Modal open={openAttachments} onClose={handleCloseAttachments}>
              <Paper
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  padding: "25px",
                  width: "100%",
                }}
              >
                <Typography variant="h6">
                  {editedItem ? editedItem.Name : ""} Attachments
                </Typography>
                <Typography>HP Used: {editedItem.Attachments?.reduce((sum, attachment) => sum + (parseInt(attachment.HP) || 0), 0) + (editedItem.VehicleWeapons?.reduce((sum, weapon) => sum + (weapon.Added ? 1 : 0), 0) || 0) || 0} / {editedItem.HP}</Typography>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <TableContainer
                      component={Paper}
                      style={{
                        maxHeight: "calc(100vh - 30vh)",
                        minHeight: "20vh",
                        overflowY: "auto",
                      }}
                    >
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="center">HP</TableCell>
                            <TableCell align="right">Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {attachments
                            .filter((a) => {
                              const isVehicle = a.Type === "Vehicle";

                              const typeMatch = (!a.TypeLimit || (
                                Array.isArray(a.TypeLimit.Type)
                                  ? a.TypeLimit.Type.some(t => t === editedItem.Type)
                                  : (a.TypeLimit.Type === editedItem.Type))
                              )

                              const categoryMatch = (!a.CategoryLimit || (
                                Array.isArray(a.CategoryLimit) &&
                                (Array.isArray(editedItem.Categories)
                                  ? editedItem.Categories.some(cat => a.CategoryLimit.includes(cat))
                                  : a.CategoryLimit.includes(editedItem.Categories))
                              ));

                              const sizeMatch = (!a.MaxSize || (parseInt(a.MaxSize) >= parseInt(editedItem.Silhouette))) &&
                                (!a.MinSize || (parseInt(a.MinSize) <= parseInt(editedItem.Silhouette)))

                              const itemMatch = !a.ItemLimit || a.ItemLimit.Key === editedItem.Key;

                              const inItem = editedItem.Attachments?.find((at) => at.Key === a.Key)

                              const HPvalid = (a.HP || 0) <= (editedItem.HP - (editedItem.Attachments?.reduce((sum, attachment) => sum + (parseInt(attachment.HP) || 0), 0) || 0) - (editedItem.VehicleWeapons?.reduce((sum, weapon) => sum + (weapon.Added ? 1 : 0), 0) || 0))

                              return isVehicle && typeMatch && categoryMatch && itemMatch && HPvalid && sizeMatch && !inItem;
                            }).sort((a, b) => {
                              return a.Name.localeCompare(b.Name);
                            })
                            .map((attachment, index) => (
                              <TableRow
                                key={attachment.Key + index}
                                hover
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  setSelectedAttachment({ ...attachment })
                                }
                              >
                                <TableCell>{attachment.Name}</TableCell>
                                <TableCell align="center">
                                  {attachment.HP || 0}
                                </TableCell>
                                <TableCell align="right">
                                  {attachment.Price}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <div
                          style={{ padding: "15px" }}
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(
                              selectedAttachment.Description,
                            ),
                          }}
                        />
                        {selectedAttachment.Source && (
                          <Typography>
                            {formatSource(selectedAttachment.Source)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {selectedAttachment && (
                          <>
                            <Typography variant="h6">
                              {selectedAttachment.Name}
                            </Typography>
                            <Typography sx={{ mt: "40px" }}>
                              Price: {selectedAttachment.Price}
                            </Typography>
                            <Typography sx={{ mt: "5px" }}>
                              Rarity: {selectedAttachment.Rarity}
                            </Typography>
                            <Typography sx={{ mt: "5px" }}>
                              HP: {selectedAttachment.HP}
                            </Typography>
                            {selectedAttachment.BaseMods && (
                              <Typography sx={{ mt: "5px" }}>
                                Base Modifiers:{" "}
                                {selectedAttachment.BaseMods.map(
                                  (mod, index) => (
                                    <div key={mod.MiscDesc + index}>
                                      <div
                                        style={{ padding: "5px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: MarkedText.renderer(
                                            mod.MiscDesc,
                                          ),
                                        }}
                                      />
                                      {mod.Key && (
                                        <div
                                          style={{ padding: "5px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(
                                              qualitiesData.find(
                                                (q) => q.Key === mod.Key,
                                              )
                                                ? (Math.abs(mod.Count) || "") +
                                                " " +
                                                qualitiesData.find(
                                                  (q) => q.Key === mod.Key,
                                                ).ModDesc +
                                                (mod.Count < 0
                                                  ? " -1"
                                                  : "") +
                                                (mod.Count > 1 ||
                                                  mod.Count < -1
                                                  ? " mods"
                                                  : " mod")
                                                : talents.find(
                                                  (t) => t.Key === mod.Key,
                                                )
                                                  ? "Innate Talent: " +
                                                  talents.find(
                                                    (t) => t.Key === mod.Key,
                                                  ).Name
                                                  : skills.find(
                                                    (s) =>
                                                      s.Key === mod.Key,
                                                  )
                                                    ? mod.Count +
                                                    " Skill: " +
                                                    skills.find(
                                                      (s) =>
                                                        s.Key === mod.Key,
                                                    ).skill +
                                                    (mod.Count > 1
                                                      ? " mods"
                                                      : " mod")
                                                    : "",
                                            ),
                                          }}
                                        />
                                      )}
                                    </div>
                                  ),
                                )}
                              </Typography>
                            )}
                            {selectedAttachment.AddedMods && (
                              <Typography sx={{ mt: "5px" }}>
                                Modification Options:{" "}
                                {selectedAttachment.AddedMods.map(
                                  (mod, index) => (
                                    <div key={mod.MiscDesc + mod.Key + index}>
                                      <div
                                        style={{ padding: "5px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: MarkedText.renderer(
                                            mod.MiscDesc,
                                          ),
                                        }}
                                      />
                                      {mod.Key && (
                                        <div
                                          style={{ padding: "5px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: MarkedText.renderer(
                                              qualitiesData.find(
                                                (q) => q.Key === mod.Key,
                                              )
                                                ? mod.Count +
                                                " " +
                                                qualitiesData.find(
                                                  (q) => q.Key === mod.Key,
                                                ).ModDesc +
                                                (mod.Count > 1
                                                  ? " mods"
                                                  : " mod")
                                                : talents.find(
                                                  (t) => t.Key === mod.Key,
                                                )
                                                  ? "Innate Talent: " +
                                                  talents.find(
                                                    (t) => t.Key === mod.Key,
                                                  ).Name
                                                  : skills.find(
                                                    (s) =>
                                                      s.Key === mod.Key,
                                                  )
                                                    ? mod.Count +
                                                    " Skill: " +
                                                    skills.find(
                                                      (s) =>
                                                        s.Key === mod.Key,
                                                    ).skill +
                                                    (mod.Count > 1
                                                      ? " mods"
                                                      : " mod")
                                                    : "",
                                            ),
                                          }}
                                        />
                                      )}
                                    </div>
                                  ),
                                )}
                              </Typography>
                            )}
                            <Button
                              onClick={() =>
                                handleSaveAttachments(
                                  editedItem,
                                  selectedAttachment,
                                )
                              }
                            >
                              Buy
                            </Button>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Modal>
          )
        }
      </Paper >
    </>
  );
}

export default App;
