import { Paper, Typography, TextField, Grid, IconButton, Button, Select, Checkbox, MenuItem, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import skills from '../data/skills.json'
import characteristics from '../data/characteristics.json'

const SpeciesEdit = ({ editedItem, index, appFiles, setAppFiles }) => {
    const talents = JSON.parse(appFiles.talents)

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles["species"]);
        newCat[index] = newItem;
        newFiles["species"] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    if (editedItem.OptionChoices && !Array.isArray(editedItem.OptionChoices)) { editedItem.OptionChoices = [editedItem.OptionChoices] }
    for (let o in editedItem.OptionChoices) {
        let choice = editedItem.OptionChoices[o]
        if (choice.Options && !Array.isArray(choice.Options)) {
            choice.Options = [choice.Options]
            for (let o in choice.Options) {
                let opt = choice.Options[o]
                if (opt.DieModifiers && !Array.isArray(opt.DieModifiers)) {
                    opt.DieModifiers = [opt.DieModifiers]
                }
            }
        }
    }
    if (editedItem.SkillModifiers && !Array.isArray(editedItem.SkillModifiers)) { editedItem.SkillModifiers = [editedItem.SkillModifiers] }
    if (editedItem.TalentModifiers && !Array.isArray(editedItem.TalentModifiers)) { editedItem.TalentModifiers = [editedItem.TalentModifiers] }
    if (editedItem.SubSpeciesList) {
        for (let ss in editedItem.SubSpeciesList) {
            let subspec = editedItem.SubSpeciesList[ss]
            if (subspec.OptionChoices && !Array.isArray(subspec.OptionChoices)) { subspec.OptionChoices = [subspec.OptionChoices] }
            for (let o in subspec.OptionChoices) {
                let choice = subspec.OptionChoices[o]
                if (choice.Options && !Array.isArray(choice.Options)) {
                    choice.Options = [choice.Options]
                    for (let o in choice.Options) {
                        let opt = choice.Options[o]
                        if (opt.DieModifiers && !Array.isArray(opt.DieModifiers)) {
                            opt.DieModifiers = [opt.DieModifiers]
                        }
                    }
                }
            }
            if (subspec.SkillModifiers && !Array.isArray(subspec.SkillModifiers)) { subspec.SkillModifiers = [subspec.SkillModifiers] }
            if (subspec.TalentModifiers && !Array.isArray(subspec.TalentModifiers)) { subspec.TalentModifiers = [subspec.TalentModifiers] }
        }
    }

    return (
        <>
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%"
                }}
                label="Image URL"
                value={editedItem?.imageUrl || ""}
                onChange={(e) => {
                    handleEdit({ ...editedItem, imageUrl: e.target.value })
                }}
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%"
                }}
                label="Thumbnail URL"
                value={editedItem?.thumbnailUrl || ""}
                onChange={(e) => {
                    handleEdit({ ...editedItem, thumbnailUrl: e.target.value })
                }}
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "100px"
                }}
                label="Brawn"
                value={editedItem?.StartingChars?.Brawn || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newChars = { ...editedItem.StartingChars || {} }
                        newChars["Brawn"] = e.target.value
                        handleEdit({ ...editedItem, StartingChars: newChars })
                    }
                }}
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "100px"
                }}
                label="Agility"
                value={editedItem?.StartingChars?.Agility || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newChars = { ...editedItem.StartingChars || {} }
                        newChars['Agility'] = e.target.value
                        handleEdit({ ...editedItem, StartingChars: newChars })
                    }
                }
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "100px"
                }}
                label="Intellect"
                value={editedItem?.StartingChars?.Intellect || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newChars = { ...editedItem.StartingChars || {} }
                        newChars['Intellect'] = e.target.value
                        handleEdit({ ...editedItem, StartingChars: newChars })
                    }
                }
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "100px"
                }}
                label="Cunning"
                value={editedItem?.StartingChars?.Cunning || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newChars = { ...editedItem.StartingChars || {} }
                        newChars['Cunning'] = e.target.value
                        handleEdit({ ...editedItem, StartingChars: newChars })
                    }
                }
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "100px"
                }}
                label="Willpower"
                value={editedItem?.StartingChars?.Willpower || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newChars = { ...editedItem.StartingChars || {} }
                        newChars['Willpower'] = e.target.value
                        handleEdit({ ...editedItem, StartingChars: newChars })
                    }
                }
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "100px"
                }}
                label="Presence"
                value={editedItem?.StartingChars?.Presence || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newChars = { ...editedItem.StartingChars || {} }
                        newChars['Presence'] = e.target.value
                        handleEdit({ ...editedItem, StartingChars: newChars })
                    }
                }
                }
            />
            <br />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "150px"
                }}
                label="Wound Threshold"
                value={editedItem?.StartingAttrs?.WoundThreshold || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newAttrs = { ...editedItem.StartingAttrs || {} }
                        newAttrs["WoundThreshold"] = e.target.value
                        handleEdit({ ...editedItem, StartingAttrs: newAttrs })
                    }
                }
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "150px"
                }}
                label="Strain Threshold"
                value={editedItem?.StartingAttrs?.StrainThreshold || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newAttrs = { ...editedItem.StartingAttrs || {} }
                        newAttrs["StrainThreshold"] = e.target.value
                        handleEdit({ ...editedItem, StartingAttrs: newAttrs })
                    }
                }
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    maxWidth: "150px"
                }}
                label="Experience"
                value={editedItem?.StartingAttrs?.Experience || 0}
                onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                        let newAttrs = { ...editedItem.StartingAttrs || {} }
                        newAttrs["Experience"] = e.target.value
                        handleEdit({ ...editedItem, StartingAttrs: newAttrs })
                    }
                }
                }
            />

            <Typography variant="h5">Skills:</Typography>
            {editedItem.SkillModifiers && editedItem.SkillModifiers.map((mod, index) => (
                <>
                    <Select
                        value={mod.Key || ''}
                        onChange={(e) => {
                            let newSkills = [...editedItem.SkillModifiers || []];
                            newSkills[index].Key = e.target.value;
                            handleEdit({ ...editedItem, SkillModifiers: newSkills });
                        }}
                        sx={{ minWidth: "150px" }}
                    >
                        {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                            <MenuItem key={skill.Key} value={skill.Key}>
                                {skill.skill}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label="Ranks"
                        value={mod.RankStart || 0}
                        onChange={(e) => {
                            let newSkills = [...editedItem.SkillModifiers || []];
                            newSkills[index].RankStart = e.target.value;
                            handleEdit({ ...editedItem, SkillModifiers: newSkills });
                        }}
                        sx={{ maxWidth: "90px", marginLeft: 2 }}
                    />
                    <TextField
                        label="Starting Rank Limit"
                        value={mod.RankLimit || 2}
                        onChange={(e) => {
                            let newSkills = [...editedItem.SkillModifiers || []];
                            newSkills[index].RankLimit = e.target.value;
                            handleEdit({ ...editedItem, SkillModifiers: newSkills });
                        }}
                        sx={{ width: "120px", marginLeft: 2 }}
                    />
                    {(editedItem.SkillModifiers) && <IconButton
                        onClick={(e) => {
                            let newSkills = [...editedItem.SkillModifiers];
                            newSkills.splice(index, 1);
                            if (newSkills.length === 0) {
                                const { SkillModifiers, ...rest } = editedItem;
                                handleEdit(rest);
                            } else {
                                handleEdit({ ...editedItem, SkillModifiers: newSkills });
                            }
                        }}

                        sx={{ maxWidth: "30px", marginTop: 2 }}
                        size="small"
                        className="no-print"
                    >
                        <CloseIcon />
                    </IconButton>}
                    <br />
                </>
            ))}
            <Button
                onClick={() => {
                    let newSkills = [...editedItem.SkillModifiers || []];
                    newSkills.push({ Key: "", RankStart: 0, RankLimit: 2 })
                    handleEdit({ ...editedItem, SkillModifiers: newSkills });
                }}
                className="no-print"
            >
                Add Skill
            </Button>

            <Typography variant="h5">Talents:</Typography>
            {editedItem.TalentModifiers &&
                editedItem.TalentModifiers.map((talent, index) => (
                    <>
                        <Select
                            sx={{ minWidth: "75px" }}
                            value={talent.Key || ''}
                            onChange={(e) => {
                                let newTalents = [...editedItem.TalentModifiers];
                                newTalents[index] = { Key: e.target.value, RankAdd: 1 };
                                handleEdit({ ...editedItem, TalentModifiers: newTalents });
                            }}
                        >
                            {talents.sort((a, b) => a.Name.localeCompare(b.Name)).map((tal) => (
                                <MenuItem key={tal.Key} value={tal.Key}>
                                    {tal.Name}
                                </MenuItem>
                            ))}
                        </Select>
                        <IconButton
                            onClick={(e) => {
                                let newTalents = [...editedItem.TalentModifiers];
                                newTalents.splice(index, 1);
                                handleEdit({ ...editedItem, TalentModifiers: newTalents });
                            }}
                            sx={{ maxWidth: "30px" }}
                            size="small"
                            className="no-print"
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                ))
            }
            <Button
                onClick={() => {
                    let newTalents = [...editedItem.TalentModifiers || []];
                    newTalents.push({ Key: "", RankAdd: 1 });
                    handleEdit({ ...editedItem, TalentModifiers: newTalents });
                }}
                className="no-print"
            >
                Add Talent
            </Button>

            <Typography variant="h5">Choices:</Typography>
            {editedItem.OptionChoices && (
                <Grid container>
                    {editedItem.OptionChoices.map((choice, choiceIndex) => (
                        <Paper variant="outlined">
                            <Grid item key={choiceIndex} sx={{ margin: "7.5px" }}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <TextField
                                            label="Choice Name"
                                            fullWidth
                                            value={choice.Name || ""}
                                            onChange={(e) => {
                                                let newChoices = [...editedItem.OptionChoices];
                                                newChoices[choiceIndex].Name = e.target.value;
                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={(e) => {
                                                let newChoices = [...editedItem.OptionChoices];
                                                newChoices.splice(choiceIndex, 1);
                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                            }}
                                            sx={{ maxWidth: "30px", marginTop: 2 }}
                                            size="small"
                                            className="no-print"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                {choice.Options && Array.isArray(choice.Options) && (
                                    <Box sx={{ marginTop: 2 }}>
                                        <Typography>Options:</Typography>
                                        {choice.Options.map((opt, optIndex) => (
                                            <Box key={optIndex} sx={{ padding: "10px", marginBottom: "10px" }}>
                                                <TextField
                                                    label="Option Name"
                                                    sx={{ minWidth: "300px" }}
                                                    value={opt.Name || ""}
                                                    onChange={(e) => {
                                                        let newChoices = [...editedItem.OptionChoices];
                                                        newChoices[choiceIndex].Options[optIndex].Name = e.target.value;
                                                        handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                    }}
                                                />
                                                <TextField
                                                    label="Description"
                                                    sx={{ minWidth: "500px", marginLeft: 2 }}
                                                    value={opt.Description || ""}
                                                    onChange={(e) => {
                                                        let newChoices = [...editedItem.OptionChoices];
                                                        newChoices[choiceIndex].Options[optIndex].Description = e.target.value;
                                                        handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                    }}
                                                />
                                                <IconButton
                                                    onClick={(e) => {
                                                        let newChoices = [...editedItem.OptionChoices];
                                                        newChoices[choiceIndex].Options.splice(optIndex, 1);
                                                        handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                    }}
                                                    sx={{ maxWidth: "30px", marginLeft: 2 }}
                                                    size="small"
                                                    className="no-print"
                                                >
                                                    <CloseIcon />
                                                </IconButton>

                                                <Typography>Skill:</Typography>
                                                {opt.SkillModifiers && (
                                                    <>
                                                        <Select
                                                            value={opt.SkillModifiers.Key || ''}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].SkillModifiers.Key = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ minWidth: "100px" }}
                                                        >
                                                            {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                                                <MenuItem key={skill.Key} value={skill.Key}>
                                                                    {skill.skill}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <TextField
                                                            label="Ranks"
                                                            value={opt.SkillModifiers.RankStart || 0}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].SkillModifiers.RankStart = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                        />
                                                    </>
                                                )}
                                                {(!opt.SkillModifiers) && <Button
                                                    onClick={() => {
                                                        let newChoices = [...editedItem.OptionChoices];
                                                        let newSkills = { Key: "", RankStart: 1, RankLimit: "2" };
                                                        newChoices[choiceIndex].Options[optIndex].SkillModifiers = newSkills;
                                                        handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                    }}
                                                    className="no-print"
                                                >
                                                    Add Skill
                                                </Button>}
                                                {(opt.SkillModifiers) && <IconButton
                                                    onClick={(e) => {
                                                        let newChoices = [...editedItem.OptionChoices];
                                                        delete newChoices[choiceIndex].Options[optIndex].SkillModifiers;
                                                        handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                    }}
                                                    sx={{ maxWidth: "30px", marginTop: 2 }}
                                                    size="small"
                                                    className="no-print"
                                                >
                                                    <CloseIcon />
                                                </IconButton>}
                                                <Typography>Roll Modifiers:</Typography>
                                                {opt.DieModifiers && opt.DieModifiers.map((mod, i) => (
                                                    <>
                                                        <Select
                                                            value={mod.SkillKey || ''}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers[i].SkillKey = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ minWidth: "150px" }}
                                                        >
                                                            {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                                                <MenuItem key={skill.Key} value={skill.Key}>
                                                                    {skill.skill}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <TextField
                                                            label="Success"
                                                            value={mod.SuccessCount || 0}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers[i].SuccessCount = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                        />
                                                        <TextField
                                                            label="Advantage"
                                                            value={mod.AdvantageCount || 0}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers[i].AdvantageCount = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                        />
                                                        <TextField
                                                            label="Boost"
                                                            value={mod.BoostCount || 0}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers[i].BoostCount = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                        />
                                                        <TextField
                                                            label="Setback"
                                                            value={mod.AddSetbackCount || 0}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers[i].AddSetbackCount = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                        />
                                                        <TextField
                                                            label="Remove Setback"
                                                            value={mod.SetbackCount || 0}
                                                            onChange={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers[i].SetbackCount = e.target.value;
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ maxWidth: "130px", marginLeft: 2 }}
                                                        />
                                                        {(opt.DieModifiers) && <IconButton
                                                            onClick={(e) => {
                                                                let newChoices = [...editedItem.OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers.splice(i, 1);
                                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                            }}
                                                            sx={{ maxWidth: "30px", marginTop: 2 }}
                                                            size="small"
                                                            className="no-print"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>}
                                                        <p />
                                                    </>
                                                ))}
                                                <Button
                                                    onClick={() => {
                                                        let newChoices = [...editedItem.OptionChoices];
                                                        let blankMod = { SkillKey: "" };
                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers ?
                                                            newChoices[choiceIndex].Options[optIndex].DieModifiers.push(blankMod) :
                                                            newChoices[choiceIndex].Options[optIndex].DieModifiers = [blankMod];
                                                        handleEdit({ ...editedItem, OptionChoices: newChoices });
                                                    }}
                                                    className="no-print"
                                                >
                                                    Add Roll Modifier
                                                </Button>
                                            </Box>
                                        ))}

                                        <Button
                                            onClick={() => {
                                                let newChoices = [...editedItem.OptionChoices];
                                                newChoices[choiceIndex].Options.push({ Key: editedItem.Key + Date.now(), Name: "", Description: "" });
                                                handleEdit({ ...editedItem, OptionChoices: newChoices });
                                            }}
                                            sx={{ marginTop: 1 }}
                                            className="no-print"
                                        >
                                            Add New Option
                                        </Button>
                                    </Box>
                                )}
                            </Grid>

                        </Paper>
                    ))}
                </Grid>
            )}
            <Button
                onClick={() => {
                    let newChoices = [...editedItem.OptionChoices || []];
                    newChoices.push({ Key: editedItem.Key + Date.now(), Name: "", Options: [] });
                    handleEdit({ ...editedItem, OptionChoices: newChoices });
                }}
                className="no-print"
            >
                Add New Choice
            </Button>
            <Typography variant="h5">SubSpecies:</Typography>
            {editedItem.SubSpeciesList && editedItem.SubSpeciesList.map((subspecies, ssIndex) => (
                <Paper variant="outlined">
                    <p />
                    <TextField
                        label="Name"
                        value={subspecies.Name || ""}
                        onChange={(e) => {
                            let newSSList = [...editedItem.SubSpeciesList]
                            newSSList[ssIndex].Name = e.target.value
                            handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                        }} />
                    <p />
                    <TextField
                        label="Description"
                        fullWidth
                        value={subspecies.Description || ""}
                        onChange={(e) => {
                            let newSSList = [...editedItem.SubSpeciesList]
                            newSSList[ssIndex].Description = e.target.value
                            handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                        }} />
                    <p />
                    <Typography>Skills:</Typography>
                    {editedItem.SubSpeciesList[ssIndex].SkillModifiers && editedItem.SubSpeciesList[ssIndex].SkillModifiers.map((mod, index) => (
                        <>
                            <Select
                                value={mod.Key || ''}
                                onChange={(e) => {
                                    let newSSList = [...editedItem.SubSpeciesList]
                                    let newSkills = [...editedItem.SubSpeciesList[ssIndex].SkillModifiers || []];
                                    newSkills[index].Key = e.target.value;
                                    newSSList[ssIndex].SkillModifiers = newSkills
                                    handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                }}
                                sx={{ minWidth: "150px" }}
                            >
                                {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                    <MenuItem key={skill.Key} value={skill.Key}>
                                        {skill.skill}
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                label="Ranks"
                                value={mod.RankStart || 0}
                                onChange={(e) => {
                                    let newSSList = [...editedItem.SubSpeciesList]
                                    let newSkills = [...editedItem.SubSpeciesList[ssIndex].SkillModifiers || []];
                                    newSkills[index].RankStart = e.target.value;
                                    newSSList[ssIndex].SkillModifiers = newSkills
                                    handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                }}
                                sx={{ maxWidth: "90px", marginLeft: 2 }}
                            />
                            <TextField
                                label="Starting Rank Limit"
                                value={mod.RankLimit || 2}
                                onChange={(e) => {
                                    let newSSList = [...editedItem.SubSpeciesList]
                                    let newSkills = [...editedItem.SubSpeciesList[ssIndex].SkillModifiers || []];
                                    newSkills[index].RankLimit = e.target.value;
                                    newSSList[ssIndex].SkillModifiers = newSkills
                                    handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                }}
                                sx={{ width: "120px", marginLeft: 2 }}
                            />
                            {(editedItem.SubSpeciesList[ssIndex].SkillModifiers) && <IconButton
                                onClick={(e) => {
                                    let newSSList = [...editedItem.SubSpeciesList]
                                    let newSkills = [...editedItem.SubSpeciesList[ssIndex].SkillModifiers || []];
                                    newSkills.splice(index, 1);
                                    if (newSkills.length === 0) {
                                        const { SkillModifiers, ...rest } = newSSList[ssIndex];
                                        newSSList[ssIndex] = rest
                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                    } else {
                                        newSSList[ssIndex].SkillModifiers = newSkills
                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                    }
                                }}

                                sx={{ maxWidth: "30px", marginTop: 2 }}
                                size="small"
                                className="no-print"
                            >
                                <CloseIcon />
                            </IconButton>}
                            <br />
                        </>
                    ))}
                    <Button
                        onClick={() => {
                            let newSSList = [...editedItem.SubSpeciesList]
                            let newSkills = [...editedItem.SubSpeciesList[ssIndex].SkillModifiers || []];
                            newSkills.push({ Key: "", RankStart: 0, RankLimit: 2 })
                            newSSList[ssIndex].SkillModifiers = newSkills
                            handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                        }}
                        className="no-print"
                    >
                        Add Skill
                    </Button>

                    <Typography>Talents:</Typography>
                    {editedItem.SubSpeciesList[ssIndex].TalentModifiers &&
                        editedItem.SubSpeciesList[ssIndex].TalentModifiers.map((talent, index) => (
                            <>
                                <Select
                                    sx={{ minWidth: "75px" }}
                                    value={talent.Key || ''}
                                    onChange={(e) => {
                                        let newSSList = [...editedItem.SubSpeciesList]
                                        let newTalents = [...editedItem.SubSpeciesList[ssIndex].TalentModifiers];
                                        newTalents[index] = { Key: e.target.value, RankAdd: 1 };
                                        newSSList[ssIndex].TalentModifiers = newTalents
                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                    }}
                                >
                                    {talents.sort((a, b) => a.Name.localeCompare(b.Name)).map((tal) => (
                                        <MenuItem key={tal.Key} value={tal.Key}>
                                            {tal.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <IconButton
                                    onClick={(e) => {
                                        let newSSList = [...editedItem.SubSpeciesList]
                                        let newTalents = [...editedItem.SubSpeciesList[ssIndex].TalentModifiers];
                                        newTalents.splice(index, 1);
                                        newSSList[ssIndex].TalentModifiers = newTalents
                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                    }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>
                        ))
                    }
                    <Button
                        onClick={() => {
                            let newSSList = [...editedItem.SubSpeciesList]
                            let newTalents = [...editedItem.SubSpeciesList[ssIndex].TalentModifiers || []];
                            newTalents.push({ Key: "", RankAdd: 1 });
                            newSSList[ssIndex].TalentModifiers = newTalents
                            handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                        }}
                        className="no-print"
                    >
                        Add Talent
                    </Button>

                    <Typography>Choices:</Typography>
                    {editedItem.SubSpeciesList[ssIndex].OptionChoices && (
                        <Grid container>
                            {editedItem.SubSpeciesList[ssIndex].OptionChoices.map((choice, choiceIndex) => (
                                <Paper variant="outlined">
                                    <Grid item key={choiceIndex} sx={{ margin: "7.5px" }}>
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <TextField
                                                    label="Choice Name"
                                                    fullWidth
                                                    value={choice.Name || ""}
                                                    onChange={(e) => {
                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                        newChoices[choiceIndex].Name = e.target.value;
                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    onClick={(e) => {
                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                        newChoices.splice(choiceIndex, 1);
                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                    }}
                                                    sx={{ maxWidth: "30px", marginTop: 2 }}
                                                    size="small"
                                                    className="no-print"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        {choice.Options && Array.isArray(choice.Options) && (
                                            <Box sx={{ marginTop: 2 }}>
                                                <Typography>Options:</Typography>
                                                {choice.Options.map((opt, optIndex) => (
                                                    <Box key={optIndex} sx={{ padding: "10px", marginBottom: "10px" }}>
                                                        <TextField
                                                            label="Option Name"
                                                            sx={{ minWidth: "300px" }}
                                                            value={opt.Name || ""}
                                                            onChange={(e) => {
                                                                let newSSList = [...editedItem.SubSpeciesList]
                                                                let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].Name = e.target.value;
                                                                newSSList[ssIndex].OptionChoices = newChoices
                                                                handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                            }}
                                                        />
                                                        <TextField
                                                            label="Description"
                                                            sx={{ minWidth: "500px", marginLeft: 2 }}
                                                            value={opt.Description || ""}
                                                            onChange={(e) => {
                                                                let newSSList = [...editedItem.SubSpeciesList]
                                                                let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                newChoices[choiceIndex].Options[optIndex].Description = e.target.value;
                                                                newSSList[ssIndex].OptionChoices = newChoices
                                                                handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                            }}
                                                        />
                                                        <IconButton
                                                            onClick={(e) => {
                                                                let newSSList = [...editedItem.SubSpeciesList]
                                                                let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                newChoices[choiceIndex].Options.splice(optIndex, 1);
                                                                newSSList[ssIndex].OptionChoices = newChoices
                                                                handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                            }}
                                                            sx={{ maxWidth: "30px", marginLeft: 2 }}
                                                            size="small"
                                                            className="no-print"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>

                                                        <Typography>Skill:</Typography>
                                                        {opt.SkillModifiers && (
                                                            <>
                                                                <Select
                                                                    value={opt.SkillModifiers.Key || ''}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].SkillModifiers.Key = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ minWidth: "100px" }}
                                                                >
                                                                    {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                                                        <MenuItem key={skill.Key} value={skill.Key}>
                                                                            {skill.skill}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <TextField
                                                                    label="Ranks"
                                                                    value={opt.SkillModifiers.RankStart || 0}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].SkillModifiers.RankStart = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                                />
                                                            </>
                                                        )}
                                                        {(!opt.SkillModifiers) && <Button
                                                            onClick={() => {
                                                                let newSSList = [...editedItem.SubSpeciesList]
                                                                let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                let newSkills = { Key: "", RankStart: 1, RankLimit: "2" };
                                                                newChoices[choiceIndex].Options[optIndex].SkillModifiers = newSkills;
                                                                newSSList[ssIndex].OptionChoices = newChoices
                                                                handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                            }}
                                                            className="no-print"
                                                        >
                                                            Add Skill
                                                        </Button>}
                                                        {(opt.SkillModifiers) && <IconButton
                                                            onClick={(e) => {
                                                                let newSSList = [...editedItem.SubSpeciesList]
                                                                let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                delete newChoices[choiceIndex].Options[optIndex].SkillModifiers;
                                                                newSSList[ssIndex].OptionChoices = newChoices
                                                                handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                            }}
                                                            sx={{ maxWidth: "30px", marginTop: 2 }}
                                                            size="small"
                                                            className="no-print"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>}
                                                        <Typography>Roll Modifiers:</Typography>
                                                        {opt.DieModifiers && opt.DieModifiers.map((mod, i) => (
                                                            <>
                                                                <Select
                                                                    value={mod.SkillKey || ''}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers[i].SkillKey = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ minWidth: "150px" }}
                                                                >
                                                                    {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                                                        <MenuItem key={skill.Key} value={skill.Key}>
                                                                            {skill.skill}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <TextField
                                                                    label="Success"
                                                                    value={mod.SuccessCount || 0}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers[i].SuccessCount = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                                />
                                                                <TextField
                                                                    label="Advantage"
                                                                    value={mod.AdvantageCount || 0}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers[i].AdvantageCount = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                                />
                                                                <TextField
                                                                    label="Boost"
                                                                    value={mod.BoostCount || 0}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers[i].BoostCount = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                                />
                                                                <TextField
                                                                    label="Setback"
                                                                    value={mod.AddSetbackCount || 0}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers[i].AddSetbackCount = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ maxWidth: "90px", marginLeft: 2 }}
                                                                />
                                                                <TextField
                                                                    label="Remove Setback"
                                                                    value={mod.SetbackCount || 0}
                                                                    onChange={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers[i].SetbackCount = e.target.value;
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ maxWidth: "130px", marginLeft: 2 }}
                                                                />
                                                                {(opt.DieModifiers) && <IconButton
                                                                    onClick={(e) => {
                                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                        newChoices[choiceIndex].Options[optIndex].DieModifiers.splice(i, 1);
                                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                                    }}
                                                                    sx={{ maxWidth: "30px", marginTop: 2 }}
                                                                    size="small"
                                                                    className="no-print"
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>}
                                                                <p />
                                                            </>
                                                        ))}
                                                        <Button
                                                            onClick={() => {
                                                                let newSSList = [...editedItem.SubSpeciesList]
                                                                let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                                let blankMod = { SkillKey: "" };
                                                                newChoices[choiceIndex].Options[optIndex].DieModifiers ?
                                                                    newChoices[choiceIndex].Options[optIndex].DieModifiers.push(blankMod) :
                                                                    newChoices[choiceIndex].Options[optIndex].DieModifiers = [blankMod];
                                                                newSSList[ssIndex].OptionChoices = newChoices
                                                                handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                            }}
                                                            className="no-print"
                                                        >
                                                            Add Roll Modifier
                                                        </Button>
                                                    </Box>
                                                ))}

                                                <Button
                                                    onClick={() => {
                                                        let newSSList = [...editedItem.SubSpeciesList]
                                                        let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices];
                                                        newChoices[choiceIndex].Options.push({ Key: editedItem.Key + Date.now(), Name: "", Description: "" });
                                                        newSSList[ssIndex].OptionChoices = newChoices
                                                        handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                                                    }}
                                                    sx={{ marginTop: 1 }}
                                                    className="no-print"
                                                >
                                                    Add New Option
                                                </Button>
                                            </Box>
                                        )}
                                    </Grid>

                                </Paper>
                            ))}
                        </Grid>
                    )}
                    <Button
                        onClick={() => {
                            let newSSList = [...editedItem.SubSpeciesList]
                            let newChoices = [...editedItem.SubSpeciesList[ssIndex].OptionChoices || []];
                            newChoices.push({ Key: editedItem.Key + Date.now(), Name: "", Options: [] });
                            newSSList[ssIndex].OptionChoices = newChoices
                            handleEdit({ ...editedItem, SubSpeciesList: newSSList })
                        }}
                        className="no-print"
                    >
                        Add New Choice
                    </Button>
                </Paper>
            ))}
            <Button
                onClick={() => {
                    let newSubSpeciesList = [...editedItem.SubSpeciesList || []];
                    newSubSpeciesList.push({ Key: editedItem.Key + Date.now(), Name: "" });
                    handleEdit({ ...editedItem, SubSpeciesList: newSubSpeciesList });
                }}
            >Add Subspecies</Button>
        </>
    )
}

export default SpeciesEdit