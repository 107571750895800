
function getFullUsedQualities(character, qualities) {
  const usedQualitiesKeys = new Set();
  const usedQualities = [];

  const items = [
    ...character.Weapons || [],
    ...character.Gear || [],
    ...character.Armor || [],
  ];

  for (const item of items) {
    if (item.Qualities) {
      for (const qual of item.Qualities) {
        if (!usedQualitiesKeys.has(qual.Key)) {
          const fullQuality = qualities.find((q) => q.Key === qual.Key);
          if (fullQuality) {
            usedQualities.push(fullQuality);
            usedQualitiesKeys.add(qual.Key);
          }
        }
      }
    }
    if (item.Attachments) {
      for (const attachment of item.Attachments) {
        if (attachment.BaseMods) {
          for (const qual of attachment.BaseMods) {
            if (!usedQualitiesKeys.has(qual.Key)) {
              const fullQuality = qualities.find((q) => q.Key === qual.Key);
              if (fullQuality) {
                usedQualities.push(fullQuality);
                usedQualitiesKeys.add(qual.Key);
              }
            }
          }
        }
        if (attachment.AddedMods) {
          for (const qual of attachment.AddedMods) {
            if (!usedQualitiesKeys.has(qual.Key)) {
              const fullQuality = qualities.find((q) => q.Key === qual.Key);
              if (fullQuality) {
                usedQualities.push(fullQuality);
                usedQualitiesKeys.add(qual.Key);
              }
            }
          }
        }
      }
    }
  }
  return usedQualities;
}

export default getFullUsedQualities